import { useMutation } from "@vue/apollo-composable";
import { MutationActivityRestoreArgs } from "../types";
import { gql } from "@apollo/client";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

type Data = {
  activityRestore: boolean;
};

const MUTATION = gql`
  mutation ActivityRestore($file: Upload!, $activityId: Int!) {
    activityRestore(file: $file, activityId: $activityId)
  }
`;

export const useActivityRestore = () => {
  const toast = useToast();
  const { t } = useI18n();
  const {
    onDone,
    onError,
    loading: restoreLoading,
    mutate,
  } = useMutation<Data, MutationActivityRestoreArgs>(MUTATION, {
    context: { hasUpload: true },
  });

  onDone(({ data, errors }) => {
    const life = parseInt(process.env.VUE_APP_TOAST_LIFE);
    if (data?.activityRestore) {
      toast.add({
        severity: "success",
        summary: t("activity.restauration_success"),
        life,
      });
      setTimeout(() => window.location.reload(), life);
    } else {
      const errorMessage = errors[0]?.message;
      let message = "";
      if (errorMessage.includes("CANNOT_RESTORE")) {
        message = "CANNOT_RESTORE_WITH_DIFFERENT_EXISTING_PROJECT";
      } else if (errorMessage.includes("ACTIVITY_NOT_FOUND")) {
        message = "ACTIVITY_NOT_FOUND";
      } else if (errorMessage.includes("FILE_CORRUPTED")) {
        message = "FILE_CORRUPTED";
      }

      toast.add({
        severity: "error",
        summary: t(`activity.${message ? message : "restauration_failed"}`),
        life,
      });
    }
  });

  onError((err) => {
    toast.add({
      severity: "error",
      summary: err?.message || t("unknownError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  const submitRestore = (event: any, activityId: number) => {
    const file = event.target.files[0];
    if (!file) return;
    void mutate({ file, activityId });
  };

  return { restoreLoading, submitRestore };
};
