import axios from "axios";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export const useActivityBackup = () => {
  const downloadLoading = ref<boolean>(false);

  const toast = useToast();

  const { t } = useI18n();

  async function downloadData(activityId: number, activityName: string) {
    downloadLoading.value = true;
    axios
      .get(`${process.env.VUE_APP_API}api/activity/backup/${activityId}`, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/zip",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${activityName}-backup-${new Date().toDateString()}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
        toast.add({
          detail: t("activity.download_backup_success"),
          severity: "success",
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
      })
      .catch(() => {
        toast.add({
          detail: t("activity.download_backup_error"),
          severity: "error",
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
      })
      .finally(() => (downloadLoading.value = false));
  }

  return { downloadData, downloadLoading };
};
