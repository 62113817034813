
import { defineComponent } from "vue";
import { useActivityBackup } from "@/graphql/activity/activity-backup";
import { useActivityRestore } from "@/graphql/activity/activity-restore";

export default defineComponent({
  name: "ActivityBackup",
  props: {
    activityId: Number,
    activityName: String,
  },
  setup() {
    return {
      ...useActivityBackup(),
      ...useActivityRestore(),
    };
  },
});
