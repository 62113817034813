import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, vShow as _vShow, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_Button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadData(_ctx.activityId, _ctx.activityName))),
      loading: _ctx.downloadLoading,
      icon: "pi pi-cloud-download",
      class: "p-button-lg p-button-secondary"
    }, null, 8, ["loading"]), [
      [_directive_tooltip, _ctx.$t('activity.download_backup')]
    ]),
    _withDirectives(_createVNode(_component_Button, {
      loading: _ctx.restoreLoading,
      onClick: _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$refs['restore_database'] as any).click())),
      icon: "pi pi-database",
      label: _ctx.restoreLoading ? _ctx.$t('activity.restauration_wait') : '',
      class: "p-button-info p-button-lg"
    }, null, 8, ["loading", "label"]), [
      [_directive_tooltip, _ctx.$t('activity.restore_backup')]
    ]),
    _withDirectives(_createElementVNode("input", {
      ref: "restore_database",
      onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitRestore($event, _ctx.activityId))),
      type: "file",
      accept: "application/zip"
    }, null, 544), [
      [_vShow, false]
    ])
  ], 64))
}